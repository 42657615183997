import "./src/styles/global.css"
const ReactDOM = require("react-dom/client")

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    setTimeout(() => {
      addScript("https://fast.wistia.com/embed/medias/yrzo75bpk8.jsonp")
      addScript("https://fast.wistia.com/assets/external/E-v1.js")
    }, 1000)
  }
}
export const onInitialClientRender = () => {
  document.getElementById("___loader").style.display = "none"
}
